import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isNewStationDisable
    ? _c(
        VContainer,
        [
          _c(
            VFlex,
            { attrs: { xs9: "" } },
            [_c("view-title", { attrs: { showBreadCrum: false } })],
            1
          ),
          _c("DisabledBanner")
        ],
        1
      )
    : _c(
        VContainer,
        {
          staticStyle: { "margin-bottom": "50px" },
          attrs: { "grid-list-xl": "" }
        },
        [
          _c(
            VForm,
            {
              ref: "form",
              staticClass: "settings",
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c(
                VLayout,
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    VFlex,
                    { attrs: { xs12: "" } },
                    [
                      _c("view-title"),
                      _c("intersection-observer", {
                        on: { intersect: _vm.intersect }
                      })
                    ],
                    1
                  ),
                  _c(
                    "loading-wrapper",
                    { attrs: { loading: _vm.loading, mt: "20vh", size: 100 } },
                    [
                      _c(
                        VFlex,
                        { attrs: { xs9: "" } },
                        [
                          _c(
                            VLayout,
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                VFlex,
                                { attrs: { xs12: "" } },
                                [
                                  _c("information", {
                                    directives: [
                                      {
                                        name: "observe-visibility",
                                        rawName: "v-observe-visibility",
                                        value: _vm.visibilityChanged,
                                        expression: "visibilityChanged"
                                      }
                                    ],
                                    ref: "brandInfo",
                                    attrs: {
                                      name: _vm.brand.name,
                                      desc: _vm.brandLocationDescription,
                                      imagestyle: _vm.brand.style,
                                      tax: _vm.privateConfig.tax_rate,
                                      taxrate: _vm.brand.tax_rate,
                                      company: _vm.brand.company,
                                      sector: _vm.brand.sector,
                                      locationID: _vm.currentLocation,
                                      isSectionDisabled:
                                        _vm.isBrandConfigEditDisabled,
                                      jdeCategory: _vm.jdeCategory,
                                      dh: _vm.isDH(_vm.brand),
                                      isNew: _vm.isNew,
                                      isCentricOS: _vm.isCentricOSToggledOn,
                                      id: "toc-information"
                                    },
                                    on: {
                                      "update:name": function($event) {
                                        return _vm.$set(
                                          _vm.brand,
                                          "name",
                                          $event
                                        )
                                      },
                                      "update:desc": function($event) {
                                        _vm.brandLocationDescription = $event
                                      },
                                      "update:imagestyle": function($event) {
                                        return _vm.$set(
                                          _vm.brand,
                                          "style",
                                          $event
                                        )
                                      },
                                      "update:tax": function($event) {
                                        return _vm.$set(
                                          _vm.privateConfig,
                                          "tax_rate",
                                          $event
                                        )
                                      },
                                      "update:company": function($event) {
                                        return _vm.$set(
                                          _vm.brand,
                                          "company",
                                          $event
                                        )
                                      },
                                      "update:sector": function($event) {
                                        return _vm.$set(
                                          _vm.brand,
                                          "sector",
                                          $event
                                        )
                                      },
                                      "update:locationID": function($event) {
                                        _vm.currentLocation = $event
                                      },
                                      "update:location-i-d": function($event) {
                                        _vm.currentLocation = $event
                                      },
                                      "update:jdeCategory": function($event) {
                                        _vm.jdeCategory = $event
                                      },
                                      "update:jde-category": function($event) {
                                        _vm.jdeCategory = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                VFlex,
                                { attrs: { xs12: "" } },
                                [
                                  _c("service-fee", {
                                    directives: [
                                      {
                                        name: "observe-visibility",
                                        rawName: "v-observe-visibility",
                                        value: _vm.visibilityChanged,
                                        expression: "visibilityChanged"
                                      }
                                    ],
                                    attrs: {
                                      delivery_fee:
                                        _vm.privateConfig.delivery_fee,
                                      service_fee:
                                        _vm.privateConfig.service_fee,
                                      id: "toc-serviceFee",
                                      isSectionDisabled:
                                        _vm.isBrandConfigEditDisabled,
                                      isMSOStation: _vm.isMSOStation,
                                      isMarketplacePickupEnabled:
                                        _vm.isMarketplacePickupEnabled,
                                      isMarketplaceDelEnabled:
                                        _vm.isMarketplaceDelEnabled ||
                                        _vm.willEnableMarketplaceDelivery,
                                      brandDeliverySettings:
                                        _vm.brandDeliverySettings
                                    },
                                    on: {
                                      "update:delivery_fee": function($event) {
                                        return _vm.$set(
                                          _vm.privateConfig,
                                          "delivery_fee",
                                          $event
                                        )
                                      },
                                      "update:service_fee": function($event) {
                                        return _vm.$set(
                                          _vm.privateConfig,
                                          "service_fee",
                                          $event
                                        )
                                      },
                                      "update:brandDeliverySettings": function(
                                        $event
                                      ) {
                                        _vm.brandDeliverySettings = $event
                                      },
                                      "update:brand-delivery-settings": function(
                                        $event
                                      ) {
                                        _vm.brandDeliverySettings = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                VFlex,
                                { attrs: { xs12: "" } },
                                [
                                  _c("pickup", {
                                    directives: [
                                      {
                                        name: "observe-visibility",
                                        rawName: "v-observe-visibility",
                                        value: _vm.visibilityChanged,
                                        expression: "visibilityChanged"
                                      }
                                    ],
                                    attrs: {
                                      timeslots: _vm.brand.timeslots,
                                      pickupStatus:
                                        _vm.brand.is.pickup_supported,
                                      dh: _vm.isDH(_vm.brand),
                                      id: "toc-pickup",
                                      brand: _vm.brand,
                                      isSectionDisabled:
                                        _vm.isTimeslotEditDisabled
                                    },
                                    on: {
                                      "update:timeslots": function($event) {
                                        return _vm.$set(
                                          _vm.brand,
                                          "timeslots",
                                          $event
                                        )
                                      },
                                      "update:pickupStatus": function($event) {
                                        return _vm.$set(
                                          _vm.brand.is,
                                          "pickup_supported",
                                          $event
                                        )
                                      },
                                      "update:pickup-status": function($event) {
                                        return _vm.$set(
                                          _vm.brand.is,
                                          "pickup_supported",
                                          $event
                                        )
                                      },
                                      "update:brand": function($event) {
                                        _vm.brand = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              !_vm.frictionlessState
                                .brand_frictionless_supported
                                ? _c(
                                    VFlex,
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("delivery", {
                                        directives: [
                                          {
                                            name: "observe-visibility",
                                            rawName: "v-observe-visibility",
                                            value: _vm.visibilityChanged,
                                            expression: "visibilityChanged"
                                          }
                                        ],
                                        attrs: {
                                          enable:
                                            _vm.brand.is.delivery_supported,
                                          selectedLocations:
                                            _vm.publicConfig
                                              .delivery_destinations,
                                          selectedLocationsAutomaticTimeslot:
                                            _vm.publicConfig
                                              .delivery_destinations_automatic_timeslot,
                                          dh: _vm.isDH(_vm.brand),
                                          timeslots: _vm.brand.timeslots,
                                          isSingleTimeslot:
                                            _vm.publicConfig
                                              .show_single_timeslot,
                                          showInstructions:
                                            _vm.publicConfig.show_instructions,
                                          runnerAppEnabled:
                                            _vm.publicConfig.runner_app_enabled,
                                          id: "toc-delivery",
                                          isSectionDisabled:
                                            _vm.isTimeslotEditDisabled,
                                          isMarketplaceDisabled:
                                            _vm.isMarketplaceDisabled,
                                          brand: _vm.brand,
                                          siteDeliveryDestinations:
                                            _vm.siteDeliveryDestinations,
                                          willEnableMarketplaceDelivery:
                                            _vm.willEnableMarketplaceDelivery
                                        },
                                        on: {
                                          "update:enable": function($event) {
                                            return _vm.$set(
                                              _vm.brand.is,
                                              "delivery_supported",
                                              $event
                                            )
                                          },
                                          "update:selectedLocations": function(
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.publicConfig,
                                              "delivery_destinations",
                                              $event
                                            )
                                          },
                                          "update:selected-locations": function(
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.publicConfig,
                                              "delivery_destinations",
                                              $event
                                            )
                                          },
                                          "update:selectedLocationsAutomaticTimeslot": function(
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.publicConfig,
                                              "delivery_destinations_automatic_timeslot",
                                              $event
                                            )
                                          },
                                          "update:selected-locations-automatic-timeslot": function(
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.publicConfig,
                                              "delivery_destinations_automatic_timeslot",
                                              $event
                                            )
                                          },
                                          "update:timeslots": function($event) {
                                            return _vm.$set(
                                              _vm.brand,
                                              "timeslots",
                                              $event
                                            )
                                          },
                                          "update:isSingleTimeslot": function(
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.publicConfig,
                                              "show_single_timeslot",
                                              $event
                                            )
                                          },
                                          "update:is-single-timeslot": function(
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.publicConfig,
                                              "show_single_timeslot",
                                              $event
                                            )
                                          },
                                          "update:showInstructions": function(
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.publicConfig,
                                              "show_instructions",
                                              $event
                                            )
                                          },
                                          "update:show-instructions": function(
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.publicConfig,
                                              "show_instructions",
                                              $event
                                            )
                                          },
                                          "update:runnerAppEnabled": function(
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.publicConfig,
                                              "runner_app_enabled",
                                              $event
                                            )
                                          },
                                          "update:runner-app-enabled": function(
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.publicConfig,
                                              "runner_app_enabled",
                                              $event
                                            )
                                          },
                                          "update:brand": function($event) {
                                            _vm.brand = $event
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                VFlex,
                                { attrs: { xs12: "" } },
                                [
                                  _c("centricOS", {
                                    directives: [
                                      {
                                        name: "observe-visibility",
                                        rawName: "v-observe-visibility",
                                        value: _vm.visibilityChanged,
                                        expression: "visibilityChanged"
                                      }
                                    ],
                                    attrs: {
                                      id: "toc-CentricOS",
                                      enable: _vm.brand.is.cafe360,
                                      selectedLocalBrand:
                                        _vm.selectedCentricOSbrand,
                                      selectedLMG: _vm.selectedCentricOSLMG,
                                      isSectionDisabled:
                                        _vm.isBrandConfigEditDisabled
                                    },
                                    on: {
                                      "update:enable": function($event) {
                                        return _vm.$set(
                                          _vm.brand.is,
                                          "cafe360",
                                          $event
                                        )
                                      },
                                      "update:selectedLocalBrand": function(
                                        $event
                                      ) {
                                        _vm.selectedCentricOSbrand = $event
                                      },
                                      "update:selected-local-brand": function(
                                        $event
                                      ) {
                                        _vm.selectedCentricOSbrand = $event
                                      },
                                      "update:selectedLMG": function($event) {
                                        _vm.selectedCentricOSLMG = $event
                                      },
                                      "update:selected-l-m-g": function(
                                        $event
                                      ) {
                                        _vm.selectedCentricOSLMG = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.isBoost
                                ? _c(
                                    VFlex,
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("DiningHall", {
                                        directives: [
                                          {
                                            name: "observe-visibility",
                                            rawName: "v-observe-visibility",
                                            value: _vm.visibilityChanged,
                                            expression: "visibilityChanged"
                                          }
                                        ],
                                        attrs: {
                                          id: "toc-diningHall",
                                          enable: _vm.brand.is.dining_hall,
                                          isSectionDisabled:
                                            _vm.isBrandConfigEditDisabled
                                        },
                                        on: {
                                          "update:enable": function($event) {
                                            return _vm.$set(
                                              _vm.brand.is,
                                              "dining_hall",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                VFlex,
                                { attrs: { xs12: "" } },
                                [
                                  _c("scan-and-go", {
                                    directives: [
                                      {
                                        name: "observe-visibility",
                                        rawName: "v-observe-visibility",
                                        value: _vm.visibilityChanged,
                                        expression: "visibilityChanged"
                                      }
                                    ],
                                    attrs: {
                                      id: "toc-ScanAndGo",
                                      enable:
                                        _vm.brand.is.scan_and_go_supported,
                                      isSectionDisabled:
                                        _vm.isBrandConfigEditDisabled
                                    },
                                    on: {
                                      "update:enable": function($event) {
                                        return _vm.$set(
                                          _vm.brand.is,
                                          "scan_and_go_supported",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              !_vm.isDH(_vm.brand)
                                ? _c(
                                    VFlex,
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("assign-menu", {
                                        directives: [
                                          {
                                            name: "observe-visibility",
                                            rawName: "v-observe-visibility",
                                            value: _vm.visibilityChanged,
                                            expression: "visibilityChanged"
                                          }
                                        ],
                                        ref: "assignedMenuComponent",
                                        attrs: {
                                          allMenus: _vm.allMenus,
                                          assignedMenus: _vm.assignedMenus,
                                          menusToBeImported:
                                            _vm.menusToBeImported,
                                          showImportMenuDialog:
                                            _vm.showImportMenuDialog,
                                          company: _vm.brandCompany,
                                          id: "toc-assignMenus",
                                          isCentricOS: _vm.isCentricOSToggledOn,
                                          isSectionDisabled:
                                            _vm.isMenuAssignationDisabled,
                                          isScanAndGoToggledOn:
                                            _vm.isScanAndGoToggledOn,
                                          isLoadingMenus: _vm.isLoadingMenus
                                        },
                                        on: {
                                          "update:assignedMenus": function(
                                            $event
                                          ) {
                                            _vm.assignedMenus = $event
                                          },
                                          "update:assigned-menus": function(
                                            $event
                                          ) {
                                            _vm.assignedMenus = $event
                                          },
                                          "update:menusToBeImported": function(
                                            $event
                                          ) {
                                            _vm.menusToBeImported = $event
                                          },
                                          "update:menus-to-be-imported": function(
                                            $event
                                          ) {
                                            _vm.menusToBeImported = $event
                                          },
                                          "update:showImportMenuDialog": function(
                                            $event
                                          ) {
                                            _vm.showImportMenuDialog = $event
                                          },
                                          "update:show-import-menu-dialog": function(
                                            $event
                                          ) {
                                            _vm.showImportMenuDialog = $event
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                VFlex,
                                { attrs: { xs12: "" } },
                                [
                                  _c("kds", {
                                    ref: "kds",
                                    attrs: {
                                      email: _vm.privateConfig.email,
                                      passcode:
                                        _vm.privateConfig.kds.admin_passcode,
                                      id: "toc-kds",
                                      number: _vm.publicConfig.phone_number,
                                      message: _vm.publicConfig.message,
                                      image: _vm.publicConfig.logos.print,
                                      refund: _vm.privateConfig.payment.refund,
                                      meta: _vm.brand.meta,
                                      hasKds: _vm.publicConfig.has_kds,
                                      isSectionDisabled:
                                        _vm.isBrandConfigEditDisabled
                                    },
                                    on: {
                                      "update:email": function($event) {
                                        return _vm.$set(
                                          _vm.privateConfig,
                                          "email",
                                          $event
                                        )
                                      },
                                      "update:passcode": function($event) {
                                        return _vm.$set(
                                          _vm.privateConfig.kds,
                                          "admin_passcode",
                                          $event
                                        )
                                      },
                                      "update:number": function($event) {
                                        return _vm.$set(
                                          _vm.publicConfig,
                                          "phone_number",
                                          $event
                                        )
                                      },
                                      "update:message": function($event) {
                                        return _vm.$set(
                                          _vm.publicConfig,
                                          "message",
                                          $event
                                        )
                                      },
                                      "update:image": function($event) {
                                        return _vm.$set(
                                          _vm.publicConfig.logos,
                                          "print",
                                          $event
                                        )
                                      },
                                      "update:refund": function($event) {
                                        return _vm.$set(
                                          _vm.privateConfig.payment,
                                          "refund",
                                          $event
                                        )
                                      },
                                      "update:meta": function($event) {
                                        return _vm.$set(
                                          _vm.brand,
                                          "meta",
                                          $event
                                        )
                                      },
                                      "update:hasKds": function($event) {
                                        return _vm.$set(
                                          _vm.publicConfig,
                                          "has_kds",
                                          $event
                                        )
                                      },
                                      "update:has-kds": function($event) {
                                        return _vm.$set(
                                          _vm.publicConfig,
                                          "has_kds",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                VFlex,
                                { attrs: { xs12: "" } },
                                [
                                  _c("thirdParty", {
                                    directives: [
                                      {
                                        name: "observe-visibility",
                                        rawName: "v-observe-visibility",
                                        value: _vm.visibilityChanged,
                                        expression: "visibilityChanged"
                                      }
                                    ],
                                    attrs: {
                                      getPhoneNumber:
                                        _vm.publicConfig.get_phone_number,
                                      id: "toc-thirdParty",
                                      isSectionDisabled:
                                        _vm.isBrandConfigEditDisabled
                                    },
                                    on: {
                                      "update:getPhoneNumber": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.publicConfig,
                                          "get_phone_number",
                                          $event
                                        )
                                      },
                                      "update:get-phone-number": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.publicConfig,
                                          "get_phone_number",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                VFlex,
                                { attrs: { xs12: "" } },
                                [
                                  _c("menu-info", {
                                    directives: [
                                      {
                                        name: "observe-visibility",
                                        rawName: "v-observe-visibility",
                                        value: _vm.visibilityChanged,
                                        expression: "visibilityChanged"
                                      }
                                    ],
                                    attrs: {
                                      disclaimer:
                                        _vm.publicConfig.calorie_disclaimer,
                                      displayCalories:
                                        _vm.publicConfig.display_calories,
                                      exemptionsEnabled:
                                        _vm.brand.is.promo_exemptions_enabled,
                                      pluEnabled: _vm.brand.is.plu_enabled,
                                      localImagesEnabled:
                                        _vm.brand.is.local_images_enabled,
                                      itemDescEditEnabled:
                                        _vm.brand.is.item_desc_edit_enabled,
                                      caloriesEditEnabled:
                                        _vm.brand.is.calories_edit_enabled,
                                      itemLabelEditEnabled:
                                        _vm.brand.is.item_label_edit_enabled,
                                      globalImagesEnabled:
                                        _vm.isGlobalImagesEnabled,
                                      itemShowcaseEnabled:
                                        _vm.brand.is.item_showcase_enabled,
                                      itemMaxShowcaseItems:
                                        _vm.brandMaxShowcaseItems,
                                      specialInstructionsEnabled:
                                        _vm.brand.is
                                          .special_instructions_enabled,
                                      id: "toc-menu-info",
                                      isSectionDisabled:
                                        _vm.isBrandConfigEditDisabled
                                    },
                                    on: {
                                      "update:disclaimer": function($event) {
                                        return _vm.$set(
                                          _vm.publicConfig,
                                          "calorie_disclaimer",
                                          $event
                                        )
                                      },
                                      "update:displayCalories": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.publicConfig,
                                          "display_calories",
                                          $event
                                        )
                                      },
                                      "update:display-calories": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.publicConfig,
                                          "display_calories",
                                          $event
                                        )
                                      },
                                      "update:exemptionsEnabled": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.brand.is,
                                          "promo_exemptions_enabled",
                                          $event
                                        )
                                      },
                                      "update:exemptions-enabled": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.brand.is,
                                          "promo_exemptions_enabled",
                                          $event
                                        )
                                      },
                                      "update:pluEnabled": function($event) {
                                        return _vm.$set(
                                          _vm.brand.is,
                                          "plu_enabled",
                                          $event
                                        )
                                      },
                                      "update:plu-enabled": function($event) {
                                        return _vm.$set(
                                          _vm.brand.is,
                                          "plu_enabled",
                                          $event
                                        )
                                      },
                                      "update:localImagesEnabled": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.brand.is,
                                          "local_images_enabled",
                                          $event
                                        )
                                      },
                                      "update:local-images-enabled": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.brand.is,
                                          "local_images_enabled",
                                          $event
                                        )
                                      },
                                      "update:itemDescEditEnabled": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.brand.is,
                                          "item_desc_edit_enabled",
                                          $event
                                        )
                                      },
                                      "update:item-desc-edit-enabled": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.brand.is,
                                          "item_desc_edit_enabled",
                                          $event
                                        )
                                      },
                                      "update:caloriesEditEnabled": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.brand.is,
                                          "calories_edit_enabled",
                                          $event
                                        )
                                      },
                                      "update:calories-edit-enabled": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.brand.is,
                                          "calories_edit_enabled",
                                          $event
                                        )
                                      },
                                      "update:itemLabelEditEnabled": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.brand.is,
                                          "item_label_edit_enabled",
                                          $event
                                        )
                                      },
                                      "update:item-label-edit-enabled": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.brand.is,
                                          "item_label_edit_enabled",
                                          $event
                                        )
                                      },
                                      "update:itemShowcaseEnabled": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.brand.is,
                                          "item_showcase_enabled",
                                          $event
                                        )
                                      },
                                      "update:item-showcase-enabled": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.brand.is,
                                          "item_showcase_enabled",
                                          $event
                                        )
                                      },
                                      "update:itemMaxShowcaseItems": function(
                                        $event
                                      ) {
                                        _vm.brandMaxShowcaseItems = $event
                                      },
                                      "update:item-max-showcase-items": function(
                                        $event
                                      ) {
                                        _vm.brandMaxShowcaseItems = $event
                                      },
                                      "update:specialInstructionsEnabled": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.brand.is,
                                          "special_instructions_enabled",
                                          $event
                                        )
                                      },
                                      "update:special-instructions-enabled": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.brand.is,
                                          "special_instructions_enabled",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                VFlex,
                                { attrs: { xs12: "" } },
                                [
                                  _c("payment", {
                                    directives: [
                                      {
                                        name: "observe-visibility",
                                        rawName: "v-observe-visibility",
                                        value: _vm.visibilityChanged,
                                        expression: "visibilityChanged"
                                      }
                                    ],
                                    attrs: {
                                      brand: true,
                                      payment: _vm.privateConfig.payment,
                                      keystore: _vm.privateConfig.keystore,
                                      isBrandConfig: true,
                                      id: "toc-payment",
                                      isSectionDisabled:
                                        _vm.isBrandConfigEditDisabled,
                                      refund: _vm.privateConfig.payment.refund
                                    },
                                    on: {
                                      "update:refund": function($event) {
                                        return _vm.$set(
                                          _vm.privateConfig.payment,
                                          "refund",
                                          $event
                                        )
                                      },
                                      editKeystore: _vm.editKeystore,
                                      editPayment: _vm.editPayment
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                VFlex,
                                { attrs: { xs12: "" } },
                                [
                                  _c("mealplan", {
                                    directives: [
                                      {
                                        name: "observe-visibility",
                                        rawName: "v-observe-visibility",
                                        value: _vm.visibilityChanged,
                                        expression: "visibilityChanged"
                                      }
                                    ],
                                    attrs: {
                                      mealplan: _vm.publicConfig.mealplan,
                                      id: "toc-mealplan",
                                      isSectionDisabled:
                                        _vm.isBrandConfigEditDisabled,
                                      configLevel: "BRAND",
                                      sitePublicConfig: _vm.sitePublicConfig,
                                      sitePrivateConfig: _vm.sitePrivateConfig
                                    },
                                    on: {
                                      "update:mealplan": function($event) {
                                        return _vm.$set(
                                          _vm.publicConfig,
                                          "mealplan",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                VFlex,
                                { attrs: { xs12: "" } },
                                [
                                  _c("loyalty", {
                                    directives: [
                                      {
                                        name: "observe-visibility",
                                        rawName: "v-observe-visibility",
                                        value: _vm.visibilityChanged,
                                        expression: "visibilityChanged"
                                      }
                                    ],
                                    attrs: {
                                      id: "toc-loyalty",
                                      isSectionDisabled:
                                        _vm.isBrandConfigEditDisabled
                                    },
                                    model: {
                                      value: _vm.privateConfig.loyalty,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.privateConfig,
                                          "loyalty",
                                          $$v
                                        )
                                      },
                                      expression: "privateConfig.loyalty"
                                    }
                                  })
                                ],
                                1
                              ),
                              !_vm.isMSOStation
                                ? _c(
                                    VFlex,
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("payment-exclusions", {
                                        directives: [
                                          {
                                            name: "observe-visibility",
                                            rawName: "v-observe-visibility",
                                            value: _vm.visibilityChanged,
                                            expression: "visibilityChanged"
                                          }
                                        ],
                                        attrs: {
                                          creditCard:
                                            _vm.privateConfig
                                              .excluded_payment_methods
                                              .credit_card,
                                          cashless:
                                            _vm.privateConfig
                                              .excluded_payment_methods
                                              .cashless,
                                          badge_pay:
                                            _vm.privateConfig
                                              .excluded_payment_methods
                                              .badge_pay,
                                          excludedTenders: _vm.excludedTenders,
                                          digitalWalletPay:
                                            _vm.privateConfig
                                              .excluded_payment_methods
                                              .digital_wallet_pay,
                                          tenders: _vm.availableTenders,
                                          swipeTenders: _vm.availableMealSwipes,
                                          id: "toc-payment-exclusions",
                                          isSectionDisabled:
                                            _vm.isBrandConfigEditDisabled
                                        },
                                        on: {
                                          "update:creditCard": function(
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.privateConfig
                                                .excluded_payment_methods,
                                              "credit_card",
                                              $event
                                            )
                                          },
                                          "update:credit-card": function(
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.privateConfig
                                                .excluded_payment_methods,
                                              "credit_card",
                                              $event
                                            )
                                          },
                                          "update:cashless": function($event) {
                                            return _vm.$set(
                                              _vm.privateConfig
                                                .excluded_payment_methods,
                                              "cashless",
                                              $event
                                            )
                                          },
                                          "update:badge_pay": function($event) {
                                            return _vm.$set(
                                              _vm.privateConfig
                                                .excluded_payment_methods,
                                              "badge_pay",
                                              $event
                                            )
                                          },
                                          updateDigitalWalletPay:
                                            _vm.updateDigitalWalletPay,
                                          updateMealSwipes:
                                            _vm.updateMealSwipes,
                                          updateMealPlan: _vm.updateMealPlan
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.frictionlessState.site_frictionless_supported
                                ? _c(
                                    VFlex,
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("frictionless", {
                                        directives: [
                                          {
                                            name: "observe-visibility",
                                            rawName: "v-observe-visibility",
                                            value: _vm.visibilityChanged,
                                            expression: "visibilityChanged"
                                          }
                                        ],
                                        attrs: {
                                          id: "frictionless-detail",
                                          frictionlessProps:
                                            _vm.frictionlessBrandData,
                                          isSectionDisabled:
                                            _vm.isBrandConfigEditDisabled,
                                          configLevel: "BRAND"
                                        },
                                        on: {
                                          "update:frictionlessProps": function(
                                            $event
                                          ) {
                                            _vm.frictionlessBrandData = $event
                                          },
                                          "update:frictionless-props": function(
                                            $event
                                          ) {
                                            _vm.frictionlessBrandData = $event
                                          },
                                          submitFrictionlessStore:
                                            _vm.submitFrictionlessStore,
                                          changeThirdPartyPartner:
                                            _vm.changeThirdPartyPartner,
                                          changeThirdPartyTechnology:
                                            _vm.changeThirdPartyTechnology,
                                          changeFrictionlessAllowed:
                                            _vm.changeFrictionlessAllowed
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VFlex,
                        { staticClass: "side-menu", attrs: { xs3: "" } },
                        [
                          _c("tableOfContents", {
                            ref: "toc",
                            attrs: {
                              customClass: _vm.tocCustomClass,
                              items: _vm.tocItems,
                              excludedItems: _vm.excludedTOCItems
                            },
                            model: {
                              value: _vm.tocItems,
                              callback: function($$v) {
                                _vm.tocItems = $$v
                              },
                              expression: "tocItems"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.modified && _vm.canSaveBrandConfig
                ? _c("save-footer", {
                    attrs: {
                      cancelAction: _vm.reset,
                      saveLabel: _vm.isNew ? "Create Brand" : "Save Changes",
                      saveAction: _vm.isNew ? _vm.create : _vm.save
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }