import { render, staticRenderFns } from "./Dialog.vue?vue&type=template&id=2ea619d8&scoped=true"
import script from "./Dialog.vue?vue&type=script&lang=js"
export * from "./Dialog.vue?vue&type=script&lang=js"
import style0 from "./Dialog.vue?vue&type=style&index=0&id=2ea619d8&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ea619d8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src4042112146/src/clients/ap3/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2ea619d8')) {
      api.createRecord('2ea619d8', component.options)
    } else {
      api.reload('2ea619d8', component.options)
    }
    module.hot.accept("./Dialog.vue?vue&type=template&id=2ea619d8&scoped=true", function () {
      api.rerender('2ea619d8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/BrandSettings/DeliveryTimeslots/Dialog.vue"
export default component.exports